<template>
  <div class="course-template-add">
    <AddTemplate
      :course_id="course_id"
      @onSubmit="saveTemplate"
      @cancelCallBack="cancelCallBack"
    />
  </div>
</template>
<script>
import AddTemplate from "@/containers/CourseTemplate/AddTemplate.vue";
import { mapActions } from "vuex";
export default {
  name: "AddCourseTemp",
  data: {
    course_id: null,
  },
  components: {
    AddTemplate,
  },
  created() {
    const { course_id } = this.$route.params;
    this.course_id = course_id ? parseInt(course_id) : course_id;
  },
  methods: {
    ...mapActions(["createCourseTemplate"]),
    navToDetailView() {
      this.$router.push(`/course/${this.course_id}`);
    },
    saveTemplate(data) {
      this.createCourseTemplate({
        training_course_id: this.course_id,
        payload:data,
      }).then((res) => {
        if (res.status == 200) {
          this.navToDetailView();
        }
      });
    },
    cancelCallBack() {
      this.navToDetailView();
    },
  },
};
</script>
